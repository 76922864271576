type BookingFormState = {
  branch: any | null;
  barber: any | null;
  date: any | null;
  service: any[] | null;
  client_name: string | null;
  client_tel: string | null;
  client_mail: string | null;
  client_comment: string | null;
  client_notif: string | null;
  client_agreement: boolean;
};

export interface TrackEvents {
  "phone-clicked": [branch: "zizkov" | "nusle"];
  "booking-open-review": [bookingFormState: BookingFormState];
  "booking-finished": [bookingFormState: BookingFormState];
  "booking-services-selected": [bookingFormState: BookingFormState];
  "booking-select-content": [type: "branch" | "barber", id: string];
  "booking-form": [
    type: "success" | "failure" | "progress" | "regression",
    tabName: string
  ];
}

// type TrackArgs =
//   | phoneClicked
//   | bookingOpenReview
//   | bookingFinished
//   | bookingServicesSelected;

export interface Track {
  <Event extends keyof TrackEvents>(
    command: Event,
    ...args: TrackEvents[Event]
  ): void;
}

export const useTrack: Track = (event, ...args) => {
  const { $fbq } = useNuxtApp();
  const { gtag } = useGtag();

  /**
   * "phone-clicked"
   */
  if (event === "phone-clicked") {
    $fbq("track", "Contact", { type: "phone", branch: args[0] });
    return;
  }

  /**
   * "booking-open-review"
   */
  if (event === "booking-open-review") {
    $fbq("track", "InitiateCheckout", {
      currency: "CZK",
      value: getFormStateValue(args[0] as BookingFormState),
      content_ids: getFormStateItemIds(args[0] as BookingFormState),
      content_category: getFormStateCategory(args[0] as BookingFormState),
      num_items: getFormStateItemsLength(args[0] as BookingFormState),
    });
    gtag("event", "begin_checkout", {
      currency: "CZK",
      value: getFormStateValue(args[0] as BookingFormState),
      items: getFormStateGtagItems(args[0] as BookingFormState),
    });
    return;
  }

  /**
   * "booking-finished"
   */
  if (event === "booking-finished") {
    $fbq("track", "Lead", {
      currency: "CZK",
      value: getFormStateValue(args[0] as BookingFormState),
      content_category: getFormStateCategory(args[0] as BookingFormState),
    });
    gtag("event", "generate_lead", {
      currency: "CZK",
      value: getFormStateValue(args[0] as BookingFormState),
    });
    return;
  }

  /**
   * "booking-services-selected"
   */
  if (event === "booking-services-selected") {
    $fbq("track", "AddToCart", {
      currency: "CZK",
      value: getFormStateValue(args[0] as BookingFormState),
      content_ids: getFormStateItemIds(args[0] as BookingFormState),
    });
    gtag("event", "add_to_cart", {
      currency: "CZK",
      value: getFormStateValue(args[0] as BookingFormState),
      items: getFormStateGtagItems(args[0] as BookingFormState),
    });
    return;
  }

  /**
   * "booking-select-content"
   */
  if (event === "booking-select-content") {
    gtag("event", "select_content", {
      content_type: args[0],
      content_id: args[1],
    });
    return;
  }

  /**
   * "booking-form"
   */
  if (event === "booking-form") {
    gtag("event", "booking_form", {
      booking_form_interaction_status: args[0],
      booking_form_interaction_tab: args[1],
    });
    return;
  }
};

function getFormStateValue(state: BookingFormState) {
  return +state?.service?.reduce((acc, i) => acc + i?.price_min, 0);
}
function getFormStateItemIds(state: BookingFormState) {
  return state?.service?.map((i) => i?.id);
}
function getFormStateCategory(state: BookingFormState) {
  return state?.branch?.attributes?.title;
}
function getFormStateItemsLength(state: BookingFormState) {
  return state?.service?.length;
}
function getFormStateGtagItems(state: BookingFormState) {
  return state?.service?.map((i) => {
    return {
      item_id: i.id,
      item_name: i.title,
      index: i.weight,
      price: i.price_min,
      currency: "CZK",
    };
  });
}
